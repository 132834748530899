import React from 'react'
import styled from 'styled-components'
import { graphql, PageProps } from 'gatsby'
import tw from 'twin.macro'

import { Layout } from '../components/Layout'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { ArticleList } from '../components/ArticleList'

const IndexWrapper = styled.div`
  ${tw`py-8`}
`

type IndexPageData = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  articles: {
    edges: [{
      node: {
        title: string
      }
    }]
  }
}

const IndexPage: React.FC<PageProps<IndexPageData>> = ({
  data
}) => {
  const { articles, site } = data
  return (
    <Layout>
      <SEO title={'Design & Development News, Guides, Articles'} />
      <IndexWrapper>
        <Container>
          <ArticleList
            articles={articles.edges}
          />
        </Container>
      </IndexWrapper>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
{
  site {
    siteMetadata {
      title
    }
  }
  articles: allStrapiArticle(
    sort: {
      fields: publishdate,
      order: DESC
    }
  ) {
    edges {
      node {
        title
        slug
        excerpt
      }
    }
  }
}
`